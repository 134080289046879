import HandyCollapse from "handy-collapse";

//Full Options
const myAccrodionCustom = new HandyCollapse({
  nameSpace: "hm", // Note: Be sure to set different names when creating multiple instances
  activeClass: "is-active",
  isAnimation: true,
  closeOthers: true,
  animationSpeed: 400,
  cssEasing: "ease",
 
});
